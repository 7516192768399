const en = {
  translations: { // Same value used in config (defaultNS)
    home: {
      hero: {
        title: 'Create, sell or collect digital items.',
        subtitle: 'Unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable'
      },
      liveAuction: 'Live Auction',
      newItems: 'New Items',
      topCollection: 'Top Collection over',
      topCollectionOption: {
        last7days: 'last 7 days',
        today: 'today',
        last30days: 'last 30 days',
      },
      hotCollections: 'Hot Collections',
      topSellers: 'Top Sellers',
    },
    placeholder: {
      search: 'Search item here...',
    },
    wallet: {
      connect: 'Connect wallet',
      connectMessage: 'Connect your MetaMask wallet to Arkne',
      pleaseConnect: 'Please connect your wallet',
    },
    nft: {
      buy: 'Buy NFT',
      placeBid: 'Place a bid',
      history: 'History',
      mint: 'Mint NFT now',
      signMint: 'Sign Now & Mint later',
      title: 'Title',
      collection: 'Collection',
      description: 'Description',
      onSale: 'On Sale',
      owner: 'Owner',
      owned: 'Owned',
      price: 'Price',
      createCollection: 'Create Collection',
      creator: 'Creator',
      seller: 'Seller',
      list: 'List',
      bids: 'Bids',
      supply: 'Supply',
      royalty: 'Royalty',
      currentPrice: 'Current price',
      dateEndAuction: 'Auction will end on',
      endAuction: 'Auction has ended',
      btnendAuction: 'End Auction',
      titlePlaceHolder: 'e.g. Crypto Funk',
      descPlaceHolder: "e.g. 'This is very limited item'",
      Withdraw: "Withdraw",
      btnendAuction: "End Auction",
      Created: "Created",
    },
    explore: 'Explore',
    rankings: 'Rankings',
    activity: 'Activity',
    create: 'Create',
    browse: 'Browse',
    uploadFile: 'Upload file',
    loadMore: 'Load more',
    uploading: 'Uploading...',
    loading: 'Loading...',
    wait: 'Wait....',
    allRights: 'All rights reserved',
    madeWith: 'Made with',
    allCategories: 'All categories',
    topNFT: 'Top NFTs',
    totalSold: 'Total sold',
    noRecord: 'No Record',
    buyNow: 'Buy Now',
    onAuction: 'On Auction',
    day7: 'Last 7 days',
    hour24: 'Last 24 hours',
    day30: 'Last 30 days',
    allTime: 'All time',
    noActivity: 'No activities',
    setDisplayName: 'Set display name',
    myWallet: 'My wallet',
    myProfile: 'My profile',
    editProfile: 'Edit Profile',
    signOut: 'Sign out',
    Username: 'Username',
    Email: 'Email',
    enterYourEmail: 'Enter your email',
    Bio: 'Bio',
    addYourBio: 'Add your bio',
    Instagram: 'Instagram',
    Twitter: 'Twitter',
    Discord: 'Discord',
    Updating: 'Updating',
    updateProfile: 'Update Profile',
    profileImage: 'Profile image',
    chooseImage: 'Choose Image',
    profileBanner: 'Profile banner',
    balance : 'Balance',
    noteUploadImage : 'PNG, JPG, GIF, WEBP or MP4. Max 100mb',
    selectCollection : 'Select Collection',
    onSale : 'On Sale',
    allCollection : 'All Collections',
    SALE: 'SALE',
    AUCTION: 'AUCTION',
    BUY: 'BUY',

  }
}

export default en
