const ko = {
    translations: { // Same value used in config (defaultNS)
        home: {
            hero: {
                title: '예술이 매력적인 자산이 된다. 크리에이터를 위한 NFT 방주',
                subtitle: '사진, 음원, 영상이 NFT 아트가 되는 국내 최초 예술 생태계의 혁신'
            },
            liveAuction: '라이브 경매',
            newItems: '새 작품',
            topCollection: '베스트 컬렉션',
            topCollectionOption: {
                last7days: '지난 7일',
                today: '오늘',
                last30days: '지난 30일',
            },
            hotCollections: '인기 컬렉션',
            topSellers: '파워셀러',
        },
        placeholder: {
            search: '검색...',
        },
        wallet: {
            connect: '연결',
            connectMessage: '지갑을 연결하세요',
            pleaseConnect: '지갑을 연결하세요',
        },
        nft: {
            buy: '구매',
            placeBid: '입찰',
            history: '역사',
            mint: '즉시 민팅하기',
            signMint: '생성 & 레이지민팅',
            title: '물건',
            collection: '컬렉션',
            description: '설명',
            onSale: '판매중',
            owner: '소유자',
            owned: '소유됨',
            price: '가격',
            createCollection: '컬렉션 생성',
            creator: '크리에이터',
            seller: '판매자',
            list: '목록',
            supply: '공급',
            currentPrice: '현재 가격',
            dateEndAuction: '경매 종료일',
            endAuction: '경매가 종료되었습니다',
            btnendAuction: '경매 종료',
            Withdraw: '철회하다',
            titlePlaceHolder: "예를 들어 '크립토 펑크",
            descPlaceHolder: "예를 들어 '매우 한정된 아이템입니다'",
            Withdraw: '철회하다',
            btnendAuction: "경매 종료",
            Created: "만들어진",
            royalty: "왕족",
        },
        explore: '마켓플레이스',
        rankings: '랭킹',
        create: '민팅하기',
        browse: '찾아보기',
        uploadFile: '파일 업로드',
        loadMore: '더 불러오기',
        uploading: '업로드 중',
        loading: '로딩중',
        wait: '기다려주세요',
        activity: '활동',
        Copyright: '저작권',
        allRights: '판권 소유',
        madeWith: '로 만든',
        by: '으로',
        allCategories: '모든 카테고리',
        topNFT: '상위 NFT',
        Collection: '수집',
        Owner: '소유자',
        totalSold: '총 판매',
        Revenue: '수익',
        noRecord: '어떤 기록도 없다',
        Art: '미술',
        Music: '음악',
        Videos: '비디오',
        All: '비디오',
        buyNow: '지금 구매',
        onAuction: '경매',
        day7: '지난 7일',
        hour24: '지난 24시간',
        day30: '지난 30일',
        allTime: '항상',
        Quantity: '수량',
        Price: '가격',
        To: '큰',
        From: '에서',
        Time: '시간',
        noActivity: '활동 없음',
        setDisplayName: '표시 이름 설정',
        myWallet: '내 지갑',
        myProfile: '내 프로필',
        editProfile: '프로필 수정',
        signOut: '로그아웃',
        Copy: '복사',
        Username: '사용자 이름',
        Email: '이메일',
        enterYourEmail: '이메일 입력',
        Bio: '바이오',
        addYourBio: '약력 추가',
        Instagram: '인스타그램',
        Twitter: '트위터',
        Discord: '디스코드',
        Updating: '업데이트 중',
        updateProfile: '프로필 업데이트',
        profileImage: '프로필 이미지',
        chooseImage: '이미지 선택',
        profileBanner: '프로필 배너',
        balance : '잔액',
        noteUploadImage : 'PNG, JPG, GIF, WEBP 또는 MP4. 최대 100MB',
        selectCollection : '컬렉션 선택',
        onSale : '판매중',
        Owned : '소유',
        allCollection : '모든 컬렉션',
        Joined : '가입',
        January : '1 월',
        February : '2 월',
        March : '3 월',
        April : '4 월',
        May : '5 월',
        June : '6 월',
        July : '7 월',
        August : '8 월',
        September : '9 월',
        October : '10 월',
        November : '11 월',
        December : '12 월',
        SALE: '판매',
        AUCTION: '경매',
        BUY: '사다',
    }
}

export default ko
