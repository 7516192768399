import React from 'react';
import SliderCarousel from '../components/SliderCarouselsingle';
import CarouselCollection from '../components/CarouselCollection';
import TopCollections from '../components/TopCollections';
import NewItems from '../components/NewItems';
import AuthorList from '../components/authorList';
import AuctionItems from '../components/AuctionItems';
import Footer from '../components/footer';
import { useTranslation } from 'react-i18next';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import { navigate } from '@reach/router';


const fadeInUp = keyframes `
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const Home = ({theme, search, translate}) => {
  const { t } = useTranslation();
  return (
    <div>
      <section className="jumbotron no-bg">
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6'>
              <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600} triggerOnce>
                <h1 className="" style={{ color: '#333'}}> {t('home.hero.title')} </h1>
              </Reveal>
              <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={600} triggerOnce>
                <p className="lead">
                  {t('home.hero.subtitle')}
                </p>
              </Reveal>
              <div className="spacer-10"></div>
              <Reveal className='onStep' keyframes={fadeInUp} delay={800} duration={900} triggerOnce>
                <div className="d-flex">
                  <button onClick={() => navigate('/explore')} className="btn btn-main" style={{ marginRight: '15px' }}>{t('explore')}</button>
                  <button onClick={() => navigate('/create')} className={`btn btn-out-main ${theme ? 'bg_input theme-dark-color' : ''}`}>{t('create')}</button>
                </div>
                <div className="mb-sm-30"></div>
              </Reveal>
              <div className="spacer-double"></div>
            </div>
            <div className='col-lg-6 px-0'>
              <SliderCarousel/>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        {/* <div className='container'> */}
          <div className='row'>
            <div className='col-lg-12'>
              <h2 className='style-2'>{t('home.liveAuction')}</h2>
            </div>
          </div>
          <AuctionItems theme={theme} search={search}/>
        {/* </div> */}
      </section>

      <section className='container no-top'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='style-2'>{t('home.newItems')}</h2>
          </div>
        </div>
        <NewItems theme={theme} search={search}/>
      </section>
      <TopCollections theme={theme} search={search} translate={translate}/>

      <section className='container no-top'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='style-2'>{t('home.hotCollections')}</h2>
          </div>
        </div>
        <div className='container no-top'>
          <div className='row'>
            <div className='col-lg-12 px-0'>
              <CarouselCollection theme={theme} />
            </div>
          </div>
        </div>
      </section>

      <section className='container no-top'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='style-2'>{t('home.topSellers')}</h2>
          </div>
          <div className='col-lg-12'>
            <AuthorList theme={theme}/>
          </div>
        </div>
      </section>
      <Footer />

    </div>
  );
}

export default Home;
